import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { Link as RouterLink } from "gatsby";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

import Arrow from "../../images/arrow-right.inline.svg";
import FiredancerLogo from "../../images/firedancer/Firedancer-logo.svg";


const firedancerInfo = "Firedancer is a validator client for Solana being developed by Jump Crypto and engineered to optimize networking throughput, resilience and efficiency."

const Projects = () => {
  const [playVideos, setPlayVideos] = useState(true);
  const [counter, setCounter] = useState(0);
  const breakpoints = useBreakpoint();


  useEffect(() => {
    if (
      !breakpoints.xs &&
      !breakpoints.sm &&
      Object.keys(breakpoints).length !== 0
    ) {
      const videos = document.querySelectorAll("video");
      videos.forEach((v) => {
        if (playVideos) {
          v.play();
        } else {
          v.pause();
        }
      });
    }
  }, [playVideos, breakpoints]);

  React.useEffect(() => {
    setInterval(() => setCounter(counter + 5), 1000);
  }, [counter]);

  return (
    <Box
      component="div"
      sx={{
        maxWidth: 1044,
        mx: "auto",
        mt: 2,
        mb: { xs: 5, md: 16 },
        position: "relative",
      }}
    >

      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: { xs: "column-reverse", sm: "row" },
          alignItems: "space-around",
          justiiyContent: "space-around",
        }}
      >
        <Box
          component="div"
          sx={{
            width: { xs: "100%", sm: "100%" },
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box component="img" src={FiredancerLogo} alt="" sx={{ width: "50%", height: "auto" }} />
        </Box>
        <Box
          component="div"
          sx={{
            width: { xs: "100%", sm: "100%" },
            pr: { sm: 3.5 },
            pt: 1,
          }}
        >
          <Typography variant="h2" sx={{ mb: 2.4 }}>
            Firedancer
          </Typography>
          <Typography>{firedancerInfo}</Typography>
          <Box
            component="div"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              component={RouterLink}
              to="/firedancer/"
              sx={{ my: 4 }}
              endIcon={<Arrow />}
            >
              Explore Firedancer
            </Button>
          </Box>
        </Box>


      </Box>

    </Box >
  );
};

export default Projects;
